<!--
 * @Description: 课程详情
 * @Author: xiawenlong
 * @Date: 2021-05-20 14:30:20
 * @LastEditors: xiawenlong
 * @LastEditTime: 2021-10-12 10:00:47
-->
<template>
  <div class="course-shop-detail">
    <div class="info">
      <div class="cover">
        <img :src="detail.pic" alt="" />
      </div>
      <div class="text">
        <div class="title">{{ detail.name }}</div>
        <p>
          授课讲师：
          <span v-for="(item, index) in detail.teacherList" :key="item.teacherId"
            >{{ item.teacherName
            }}<template v-if="index + 1 != detail.teacherList.length">、</template></span
          >
        </p>
        <p class="des">课程特点：{{ detail.description }}</p>
        <!-- <div class="price">
          <span v-if="priceType == 1">￥{{ detail.quantityPrice }}元/人/年</span>
          <span v-if="priceType == 2">￥{{ detail.buyoutPrice }}元/年 </span>
        </div>
        <el-radio-group v-model="priceType">
          <el-radio v-if="detail.isQuantity" :label="1">按学员人数量</el-radio>
          <el-radio v-if="detail.isBuyout" :label="2">按包年不限量</el-radio>
        </el-radio-group>
        <div class="btns">
          <el-button v-if="detail.isSupportFreeWatch && sectionId" type="primary" @click="toPlay()"
            >试 看</el-button
          >
          <el-button @click="toCollect">{{
            detail.isCollection ? '取消收藏' : '加入收藏'
          }}</el-button>
        </div> -->
      </div>
    </div>
    <div class="container">
      <div class="main">
        <el-tabs v-model="activeTab">
          <el-tab-pane label="课程详情" name="1">
            <!-- eslint-disable-next-line vue/no-v-html -->
            <div class="content" v-html="detail.detailHtml"></div>
          </el-tab-pane>
          <el-tab-pane label="课程目录" name="2">
            <div class="video-menu">
              <div
                v-for="(section, index) in detail.sections"
                :key="section.sectionId"
                class="video-menu-item"
                @click="toPlay(section)"
              >
                <template v-if="detail.courseType == 1">
                  <div class="title">
                    <div class="live_icon">{{ ['回放', '直播'][section.sectionStage - 2] }}</div>
                    <div class="sectionName">
                      <span
                        >第<span v-show="index < 9">0</span>{{ index + 1 }}节
                        {{ section.sectionName }}</span
                      >
                      <span v-if="section.isFreeWatch" class="freelive">
                        免费试看
                      </span>
                    </div>
                  </div>
                  <div class="time date">
                    {{ section.liveSection.time }}
                  </div>
                  <div class="liveStatus">
                    <template v-if="section.liveSection.liveStatus == 2">
                      <img src="@/assets/live/live_status_icon.png" alt="" />
                      <span class="status live">直播中</span>
                    </template>
                    <span v-if="section.liveSection.liveStatus == 3" class="status end"
                      >已结束</span
                    >
                  </div>
                </template>
                <template v-else>
                  <div class="title">
                    <img
                      v-if="section.videoId"
                      class="new_icon"
                      src="@/assets/shop/video_new_icon.png"
                      alt=""
                    />
                    <img v-else class="lock_icon" src="@/assets/shop/video_lock_icon.png" alt="" />
                    <span
                      >第<span v-show="index < 9">0</span>{{ index + 1 }}节
                      {{ section.sectionName }}</span
                    >
                  </div>
                  <div class="time">{{ section.durationMinute }}分钟</div>
                  <div v-if="section.videoId && !detail.isBuy" class="freeBtn">免费试看</div>
                </template>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="课件资料" name="3"> </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>
<script>
import { courseShopInfo, collectCourse } from '@/api/course'
import to from 'await-to'
export default {
  name: 'CourseShopDetail',
  data() {
    return {
      productId: '',
      sectionId: '',
      detail: {},
      priceType: 1,
      activeTab: '2',
    }
  },
  mounted() {
    this.productId = this.$route.params.productId
    this.getDetail()
  },
  methods: {
    async getDetail() {
      const [res, err] = await to(courseShopInfo({ productId: this.productId }))
      if (err) return this.$message.warning(err.msg)
      res.data.detailHtml = res.data.detailHtml.replace(
        /<img/g,
        "<img style='max-width: 100% !important;'",
      )
      this.detail = res.data
      this.priceType = res.data.isQuantity ? 1 : 2
      for (let i = 0; i < this.detail.sections.length; i++) {
        const section = this.detail.sections[i]
        if (section.videoId) return (this.sectionId = section.sectionId)
      }
    },
    toPlay(section) {
      if (this.detail.courseType == 1) {
        this.toPlayLive(section)
      } else {
        this.toPlayVideo(section)
      }
    },
    toPlayVideo(section) {
      if (section) {
        if (!section.videoId) return this.$message.warning('请先购买该课程')
        else this.$router.push(`/course/play/${this.productId}?sectionId=${section.sectionId}`)
      } else {
        this.$router.push(`/course/play/${this.productId}?sectionId=${this.sectionId}`)
      }
    },
    toPlayLive(section) {
      if (!section.isAuth) return this.$message.warning('请先购买该课程')
      this.$router.push(`/course/live/${this.productId}?sectionId=${section.sectionId}`)
    },
    async toCollect() {
      const [, err] = await to(
        collectCourse({ productCourseId: this.productId, collectionType: 1 }),
      )
      if (err) return this.$message.warning(err.msg)
      this.detail.isCollection = !this.detail.isCollection
    },
  },
}
</script>
<style lang="scss" scoped>
.course-shop-detail {
  .info {
    width: 100%;
    background: #ffffff;
    box-sizing: border-box;
    padding: 20px;
    display: flex;
    margin-bottom: 20px;
    .cover {
      width: 296px;
      height: 159px;
      margin-right: 32px;
      img {
        @extend %width-height;
      }
    }
    .text {
      flex: 1;
      .title {
        font-size: 24px;
        color: #333333;
        line-height: 24px;
        margin-bottom: 13px;
      }
      .price {
        box-sizing: border-box;
        padding: 0 13px;
        width: 100%;
        height: 83px;
        background: rgba(255, 88, 62, 0.1);
        font-size: 42px;
        color: #ff583e;
        line-height: 83px;
        margin: 13px 0;
      }
      .des {
        margin-top: 20px;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      .el-radio-group {
        margin-bottom: 10px;
      }
      p {
        font-size: 14px;
        color: #666666;
        line-height: 25px;
      }
      .btns {
        padding-top: 10px;
      }
    }
  }
  .container {
    display: flex;
    .main {
      flex: 1;
      background-color: #fff;
      margin-right: 15px;
      ::v-deep.el-tabs {
        .el-tabs__item {
          height: 60px;
          line-height: 60px;
          font-size: 18px;
          padding: 0 26px;
        }
        .el-tabs__nav-wrap::after {
          height: 1px;
          color: #e2e2e2;
        }
      }
      .content {
        padding: 20px;
      }
      .video-menu {
        box-sizing: border-box;
        padding: 20px 36px;
        .video-menu-item {
          cursor: pointer;
          position: relative;
          margin-bottom: 24px;
          .title {
            font-size: 14px;
            color: #3e3e3e;
            margin-bottom: 7px;
            display: flex;
            align-items: center;
            .sectionName {
              display: flex;
              align-items: center;
              flex: 1;
              padding-right: 80px;
              span {
                overflow: hidden;
                max-width: 500px;
                white-space: nowrap;
                text-overflow: ellipsis;
              }
            }
            .freelive {
              display: inline-block;
              width: 58px;
              height: 18px;
              background: #ff3f29;
              border-radius: 4px;
              font-size: 12px;
              text-align: center;
              color: #ffffff;
              line-height: 18px;
              margin: 2px 9px;
            }
            .live_icon {
              width: 43px;
              height: 22px;
              background: linear-gradient(270deg, #46c597, #56d09a 30%, #57d19a 35%, #66da9d);
              border-radius: 4px;
              font-size: 12px;
              text-align: center;
              color: #ffffff;
              line-height: 22px;
              margin-right: 6px;
            }
            img {
              width: 15px;
              height: 15px;
              margin-right: 6px;
            }
            .lock_icon {
              width: 14px;
              height: 17px;
            }
          }
          .time {
            font-size: 14px;
            color: #909399;
            padding-left: 23px;
          }
          .date {
            padding-left: 49px;
          }
          .liveStatus {
            display: flex;
            align-items: center;
            position: absolute;
            right: 5px;
            top: 6px;
            img {
              width: 14px;
              height: 14px;
              margin-right: 8px;
            }
            .status {
              font-size: 14px;
              color: #909399;
              &.live {
                color: #ff7b33;
              }
            }
          }
          .freeBtn {
            position: absolute;
            right: 5px;
            top: 6px;
            font-size: 14px;
            color: #ff7b33;
            line-height: 14px;
          }
        }
      }
    }
    .side {
      width: 423px;
      > div {
        background-color: #fff;
      }
      .title {
        box-sizing: border-box;
        height: 60px;
        font-size: 18px;
        color: #3e3e3e;
        line-height: 60px;
        border-bottom: 1px solid #e2e2e2;
        padding-left: 20px;
      }
      .org {
        margin-bottom: 20px;
        .content {
          padding: 11px 15px 9px;
          img {
            display: block;
            width: 100px;
            height: 100px;
            margin: 0 auto;
            margin-bottom: 11px;
          }
          .orgName {
            font-size: 24px;
            line-height: 24px;
            color: #3e3e3e;
            text-align: center;
            margin-bottom: 12px;
          }
          .intro {
            font-size: 14px;
            color: #3e3e3e;
            line-height: 25px;
          }
        }
      }
    }
  }
}
</style>
